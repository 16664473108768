:root {
    --lightgray: #EEEEEE;
    --hover: #F9DBBB;
    --darkgray: #393E46;
    --black: #090909;
    --orange: #F55050;
    --cherryred: #990011;
    --offwhite: #FCF6F5;

}

.App {
    background-image: url(assets/bg.jpg);
    mix-blend-mode: overlay;
    background-repeat: no-repeat;
    background-color: var(--black);
    display: flex;
    flex-direction: column;
    gap: 6rem;
    overflow: hidden;
}

::-webkit-scrollbar {
    display: none;
}

.stroke-text {
    color: transparent;
    font-family: Arial, Helvetica, sans-serif;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: white;
}

.btn {
    background-color: white;
    padding: 0.5rem;
    font-weight: bold;
    border: 4px solid transparent;
    transition: 300ms all;
    text-align: center;
    justify-content: center;
}

.btn:hover {
    cursor: pointer;
}

.blur{
    background: rgba(153, 0, 17,0.69);
    position: absolute;
    border-radius: 50%;
    filter: blur(150px);
    z-index: -9;
}